<template>
  <vx-card :title="title">
    <div class="vx-row mb-6">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
      >Back
      </vs-button>
    </div>

    <div class="flex md:flex-row">
      <div class="w-full pr-6 md:basis-1/2">
        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>SOF ID</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="code" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Warehouse</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="warehouse" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Counting Type</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="countingType" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Counting Date</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="countingDate" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Posting Date</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="postingDate" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Cost Center</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="costCenter" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>External Code</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="externalCode" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Note</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-textarea class="w-full bg-grey-light" :value="note" disabled readonly/>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>System Qty As Of</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="systemQtyAsOf" disabled readonly></vs-input>
          </div>
        </div>

      </div>

      <div class="w-full md:basis-1/2">
        <div class="mb-6 vx-row">
          <vs-divider style="width: 50%; margin-left: 10%">
            List Attachment
          </vs-divider>
          <div
            class="vx-row mb-3 mt-6 w-4/4"
            style="width: 50%; margin-left: 10%"
          >
            <table class="vs-table vs-table--tbody-table">
              <template>
                <tr
                  class="tr-values vs-table--tr tr-table-state-null selected"
                  v-bind:key="tr.NameFile"
                  v-for="tr in fileAttachment"
                >
                  <td class="td vs-table--td">{{ tr.FileName }}</td>
                  <td class="td vs-table--td">
                    <vx-tooltip text="Show" v-if="tr.Path != ''">
                      <vs-button
                        type="line"
                        icon-pack="feather"
                        icon="icon-eye"
                        @click.stop="handleShowAttachment(tr.FileUrl)"
                      />
                    </vx-tooltip>
                  </td>

                </tr>
              </template>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!--    table goes here -->
    <br>
    <div class="vx-row mb-6">
      <table width="100%" class="m-3 vs-table vs-table--tbody-table">
        <thead class="vs-table--thead">
        <tr>
          <th style="width: 15%;">SKU</th>
          <th style="width: 15%;">Warehouse Area</th>
          <th style="width: 5%;">System Qty [UOM]</th>
          <th style="width: 5%;">HU</th>
          <th style="width: 5%;">HU Qty</th>
          <th style="width: 5%;">UOM Qty</th>
          <th style="width: 5%;">Adjustment Qty [UOM]</th>
          <th style="width: 6%;">Adjustment Value</th>
          <th style="width: 8%;">Batch External</th>
          <th style="width: 5%;">ED</th>
          <th style="width: 5%;">Reason</th>
        </tr>
        </thead>
        <tr v-for="(tr, indextr) in table" :key="indextr" style="padding-bottom: 5px">
          <td class="td vs-table--td" style="padding: 5px">
            <vs-input :value="tr.SkuCode ? `${tr.SkuCode} - ${tr.ItemName}` : ''" disabled readonly
                      class="w-full bg-grey-light"></vs-input>
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <vs-input :value="tr.WarehouseAreaCode ? `${tr.WarehouseAreaCode} - ${tr.WarehouseAreaName}` : ''"
                      disabled readonly class="w-full bg-grey-light"></vs-input>
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <vs-input :value="tr.SystemQtyUom !== null && tr.SystemQtyUom !== undefined ? tr.SystemQtyUom : ''"
                      disabled readonly
                      class="w-full bg-grey-light"></vs-input>
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <vs-input :value="tr.Unit ? tr.Unit : ''" disabled readonly
                      class="w-full bg-grey-light"></vs-input>
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <vs-input :value="tr.UnitQty !== null && tr.UnitQty !== undefined ? tr.UnitQty : 0" disabled readonly
                      class="w-full bg-grey-light"></vs-input>
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <vs-input
              :value="tr.UnitUOM && (tr.UomQty !== null && tr.UomQty !== undefined) ? `${tr.UnitUOM}/${tr.UomQty}` : `${tr.UnitUOM}/0`"
              disabled readonly
              class="w-full bg-grey-light"></vs-input>
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <vs-input :value="formatAdjustmentQty(tr)" disabled readonly class="w-full bg-grey-light"></vs-input>
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <vs-input :value="priceFormat(tr.AdjustmentValue) ? priceFormat(tr.AdjustmentValue) : ''" disabled readonly
                      class="w-full bg-grey-light"></vs-input>
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <vs-input v-if="tr.Status === 1 && status === 0"
                      v-model="tr.BatchExternal"
                      class="w-full bg-grey-light">
            </vs-input>
            <vs-input v-else
                      :value="tr.BatchExternal ? tr.BatchExternal : ''"
                      disabled
                      readonly
                      class="w-full bg-grey-light">
            </vs-input>
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <vs-input v-if="tr.Status === 1 && status === 0"
                      type="date" class="w-full"
                      v-model="tr.ED" name="ED"></vs-input>
            <vs-input v-else
                      :value="formatDate(tr.ED) ? formatDate(tr.ED) : ''"
                      disabled
                      readonly
                      class="w-full bg-grey-light">
            </vs-input>
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <vs-input v-if="status === 0"
                      v-model="tr.Reason"
                      class="w-full bg-grey-light">
            </vs-input>
            <vs-input v-else
                      :value="tr.Reason ? tr.Reason : ''"
                      disabled
                      readonly
                      class="w-full bg-grey-light">
            </vs-input>
          </td>
        </tr>
        <tbody>

        </tbody>
      </table>
    </div>

    <div class="mb-6 vx-row">
      <div class="w-full vx-col sm:w-1/1">
        <div
          style="position: absolute; left: 50%; -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%); ">
          <vs-button class="mb-2 mr-3" color="green" v-if="status === 0 || status === '0'"
                     @click="handleApproveAndClose(id)">
            Close and
            Approval
          </vs-button>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment/moment";

export default {
  data() {
    return {
      id: this.$route.query.id ? parseInt(this.$route.query.id) : null,
      title: 'Stock Opname Form Edit',
      code: null,
      warehouse: null,
      countingType: null,
      countingDate: null,
      postingDate: null,
      costCenter: null,
      externalCode: null,
      note: null,
      systemQtyAsOf: null,
      fileAttachment: null,
      lines: [],
      status: null,
      table: this.tableDefaultState(),
    }
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        transactionDate: null,
      };
    },
    handleShowAttachment(file) {
      window.open(file, "_blank");
    },
    handleBack() {
      this.$vs.loading();
      this.$router.go(-1);
      this.$vs.loading.close();
    },
    getData() {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/stock-opname-form/" + this.id).then((resp) => {
        if (resp.code === 200) {
          this.status = resp.data.sof.Status;
          this.code = resp.data.sof.Code;
          this.warehouse = resp.data.sof.WarehouseCode + " - " + resp.data.sof.WarehouseName;
          this.countingDate = moment(resp.data.sof.CountingDate).format('YYYY-MM-DD');
          this.postingDate = moment(resp.data.sof.PostingDate).format('YYYY-MM-DD');
          this.countingType = resp.data.sof.CountingType;
          this.costCenter = resp.data.sof.CostCenterCode + " - " + resp.data.sof.CostCenterName;
          this.externalCode = resp.data.sof.ExternalCode;
          this.note = resp.data.sof.Note;
          this.systemQtyAsOf = moment(resp.data.sof.SystemQtyAsOf).format('YYYY-MM-DD HH:mm:ss');
          this.fileAttachment = resp.data.sof_attachment;
          this.table = resp.data.sofl;
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check"
          });
        }
        this.$vs.loading.close();
      });
    },
    formatAdjustmentQty(tr) {
      if (tr.Status === 0 && tr.AdjustmentQtyUOM !== null && tr.AdjustmentQtyUOM !== undefined) {
        return `-${tr.AdjustmentQtyUOM}`;
      } else if (tr.AdjustmentQtyUOM === 0) {
        return "0";
      } else if (tr.Status === 1 && tr.AdjustmentQtyUOM !== null && tr.AdjustmentQtyUOM !== undefined) {
        return `+${tr.AdjustmentQtyUOM}`;
      }
      return tr.AdjustmentQtyUOM ? tr.AdjustmentQtyUOM : '';
    },
    formatDate(date) {
      if (!date) {
        return '';
      }
      return moment(date).format('DD MMM YYYY');
    },
    handleApproveAndClose(id) {
      this.approveID = id;
      let batchAndEdData = [];

      for (let tr of this.table) {
        if (tr.Status === 1) {
          if (!tr.BatchExternal || !tr.ED || !tr.Reason) {
            this.$vs.notify({
              title: "Validation Error",
              text: "Batch, Expired Date, and Reason are required for status 1",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-alert-circle"
            });
            return;
          }
        } else {
          if (!tr.Reason) {
            this.$vs.notify({
              title: "Validation Error",
              text: "Reason is required",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-alert-circle"
            });
            return;
          }
        }

        batchAndEdData.push({
          batch: tr.BatchExternal,
          ed: moment(tr.ED).format("YYYY-MM-DD"),
          sofl: tr.id,
          reason: tr.Reason,
        });
      }

      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to approve this data",
        accept: () => this.acceptApprove(batchAndEdData),
      });
    },
    acceptApprove(batchAndEdData) {
      this.$vs.loading();
      this.$http
        .put("/api/wms/v1/stock-opname-form/approve-and-close/" + this.approveID, {
          data: batchAndEdData,
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully send to approved request",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.handleBack();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
  },
  mounted() {
    if (this.id) {
      this.getData();
    }
  },
}
</script>
